import React, { useCallback, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { Dropdown, ExtendedDropdown, Modal } from '@strivr/ui';

import useSelector from '../hooks/useSelector';
import { CAMTenant } from '../types/api';
import styles from './tenantPickerModal.module.css';
import useClientToggle from '../hooks/useClientToggle';
import { trackUserEvent } from '../utils/tracking';

const PERMISSIONS = [
  'openid',
  'email',
  'profile',
  'asset_management:read',
  'asset_management:edit',
  'ams_group_label:read',
  'ams_group_label:write',
  'update:ams_org_relationships',
];

interface TenantPickerModalProps {
  show: boolean;
  onTenantSelectionClose: () => void;
  onTenantSelectionCancel: () => void;
}

const generateTenantDropDownOptions = (tenants: CAMTenant[]) =>
  tenants.map((tenant, i) => ({ display: tenant.name, value: i }));

const sortTenants = (tenants: CAMTenant[] | null) =>
  [...(tenants ?? [])].sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });

const TenantPickerModal: React.FC<TenantPickerModalProps> = ({
  show,
  onTenantSelectionClose,
  onTenantSelectionCancel,
}) => {
  const [selectedTenantIndex, setSelectedTenantIndex] = useState(0);
  const { tenantList } = useSelector((state) => state.main.tenants);
  const sortedTenantList = useMemo(() => sortTenants(tenantList), [tenantList]);

  const { loginWithRedirect } = useAuth0();

  const isEnabledNewTenantPicker = useClientToggle('new-tenant-picker', false);

  const onTenantSelectionConfirm = useCallback(async () => {
    if (
      sortedTenantList &&
      sortedTenantList.length > 0 &&
      selectedTenantIndex < sortedTenantList.length
    ) {
      const selectedTenant = sortedTenantList[selectedTenantIndex];

      trackUserEvent(
        'User selected tenant',
        { area: 'main' },
        { tenant: selectedTenant.name },
      );

      await loginWithRedirect({
        organization: selectedTenant.organization_id,
        scope: PERMISSIONS.join(' '),
        appState: {
          selectedTenant,
        },
      });
    }
  }, [sortedTenantList, selectedTenantIndex, loginWithRedirect]);

  return (
    <Modal
      show={show}
      title="Customer"
      confirmText="Select Customer"
      onConfirm={onTenantSelectionConfirm}
      onClose={onTenantSelectionClose}
      onCancel={onTenantSelectionCancel}
    >
      <div className={styles.container}>
        {sortedTenantList &&
          (isEnabledNewTenantPicker ? (
            <>
              <label htmlFor="CustomerDropdown">Customer Name</label>
              <ExtendedDropdown
                id="CustomerDropdown"
                onChange={setSelectedTenantIndex}
                options={generateTenantDropDownOptions(sortedTenantList)}
                className={styles.tenantPicker}
                isSearchable={true}
                searchPlaceholder="Search customer"
                caretSize={10}
                defaultValue={0}
              />
            </>
          ) : (
            <>
              <div
                className={styles.customerLabel__DEPRECATED}
                id="CustomerLabel"
              >
                Customer Name
              </div>
              <Dropdown
                aria-labelledby="CustomerLabel"
                onChange={setSelectedTenantIndex}
                options={generateTenantDropDownOptions(sortedTenantList)}
                className={styles.tenantPicker__DEPRECATED}
              />
            </>
          ))}
      </div>
    </Modal>
  );
};

export default TenantPickerModal;
